.costumCurser {
    width: 25px;
    height:  25px;
    border-radius: 50%;
    position: absolute;
    transition: transform .2s ease-in-out;
    transform-origin: center;
    pointer-events: none;
    z-index: 99;
}

nav {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1em;
    padding-top: 2em;
    position: absolute;
    top: 0;
    .innerNav{
        width: 100%;
        max-width: 1400px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .Logo{
            color: white;
            font-size: 1.5em;
            @media screen and (max-width:450px){
                font-size: 1.5em;
            }
            span{
                font-size: .7em;
                margin-left: -0.2em;
            }
        }
        .menuHolder{
            display: flex;
            align-items: center;
            a{
                color: white;
                font-size: 1.2em;
                margin-right: 1em;
                font-weight: 600;
                transition: font-size .3s ease;

            }
        }
    }
}

.burgerMenu{
    font-size: 2.2em;
    color: white;
}

.mouse {
	width: 32px;
	height: 72px;
	border: 3px solid rgba(255, 255, 255, 0.66);
	border-radius: 60px;
	position: absolute;
    bottom: 1em;
	&::before {
		content: '';
		width: 8px;
		height: 8px;
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translateX(-50%);
		background-color: rgba(255, 255, 255, 0.66);
		border-radius: 50%;
		opacity: 1;
		animation: wheel 2s infinite;
		-webkit-animation: wheel 2s infinite;
	}
}

@keyframes wheel {
	to {
		opacity: 0;
		top: 60px;
	}
}

@-webkit-keyframes wheel {
	to {
		opacity: 0;
		top: 60px;
	}
}

.Hero{
    width: 100%;
    height: 100vh;
    background-image: linear-gradient( rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7) ),url("../images/hero.jpg");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    .HeroTitle{
        color: rgba(255, 255, 255, 0.66);
        font-size: 4em;
        z-index: 99;
        text-align: center;
        max-width: 1000px;
        line-height: 1.2em;
        @media screen and (max-width : 450px) {
            font-size: 2em;
            padding: .5em;
        }
    }
}

.Projects {
    width: 100%;
    max-width: 1300px;
    padding: 1em;
    margin: 0 auto;
    &>h2{
        font-size: 2em;
    }
    .ProjectsHolder{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .Project{
            width: 400px;
            min-width: 300px;
            margin: .5em;
            position: relative;
            border-radius: 10px;
            overflow: hidden;
            
            & > img{
                width: 100%;
                height: 266.66px;
                filter: brightness(0.4);
                transition: filter .3s ease;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
                @media screen and (max-width:450px){
                    height: auto;
                }
                border-radius: 10px;
                &:hover{
                    filter: brightness(1);
                    
                }
            }
            label{
                text-align: center;
                margin: 0 auto;
                display: block;
                background-color: bisque;
                padding: .5em;
                color: rgba(0, 0, 0, 0.839);
                position: absolute;
                border-bottom-right-radius: 10px;
                top: 0;
                left: 0;
                font-size: 1.2em;
                
            }
        }
    }
}

.About{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    background-image: linear-gradient( rgba(0, 0, 0, .7), rgba(0, 0, 0, 0.7) ),url("https://images.adsttc.com/media/images/58c6/8f46/e58e/ce27/8c00/016f/large_jpg/Figura_3.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    padding: 4em 1em;
    margin-top: 2.5em;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    @media screen and (max-width : 1200px){
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        
        background-image: linear-gradient( rgba(0, 0, 0, .8), rgba(0, 0, 0, 0.8) ),url("https://images.adsttc.com/media/images/58c6/8f46/e58e/ce27/8c00/016f/large_jpg/Figura_3.jpg");
    }
    h1{
        font-size: 4em;
        color: white;
        text-align: center;
        margin-top: 0;
        @media screen and (max-width : 500px){
            font-size: 2.5em;
        }
    }
    p{
        font-size: 1.5em;
        color: white;
        text-transform: capitalize;
        text-align: center;
        line-height: 1.4em;
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
        margin-bottom: 2em;
        @media screen and (max-width : 500px){
            font-size: 1.3em;
            text-align: center;
            padding: 1em;
            line-height: 1.5em;
        }
    }
    button{
        margin: 0 auto;
        display: block;
        background-color: white;
        color: rgba(0, 0, 0, 0.804);
        border: none;
        outline: none;
        font-size: 1.2em;
        padding:.5em 1em;
        border-radius: 10px;
        
    }
}

.Services{
    width: 100%;
    margin-top: 5em;
    @media screen and (max-width:500px) {
        margin-top: 2em;
    }
    .innerServices{
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        
        h1{
            font-size: 5em;
            margin-bottom: .1em;
            @media screen and (max-width:500px) {
                font-size: 2.5em;
                text-align: center;
            }
        }
        .servicesHolder{
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            padding: 1em;
        }
        .service{
            width: fit-content;
            display: flex;
            position: relative;
            
            width: 100%;
            width: 400px;
            margin: .5em;
            overflow: hidden;
            transition: border-radius .3s ease;
            flex-grow: 1;
            max-height: 350px;
            .innerServiceHolder{
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 2em;
                label{
                    font-size: 2.5em;
                    font-weight: 700;
                    color: white;
                    margin-bottom:.4em ;
                    text-align: center;
                }
                button {
                    font-size: .9em;
                    background: white;
                    border: none;
                    outline: none;
                    padding: .8em 1.8em;
                    border-radius: 10px;
                    align-items: center;
                    transition: background-color .3s ease;
                    &:hover{
                        background-color: bisque;
                    }
                }
            }
            img{
                width: 100%;
                border-radius: 15px;
                filter: brightness(0.2);
                min-height: 304px;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
            }
        }
    }
}

.seeMore{
    display: block;
    margin: 0 auto;
    
    margin-top: 1em;
    background-color: bisque;
    padding: 1em 2em;
    width: 100%;
    max-width: 400px;
    color: rgba(0, 0, 0, 0.839);
    text-align: center;
    border-radius: 10px;
    font-size: 1.1em;
    &:hover{
        cursor: pointer;
    }
}

.showCase {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    overflow-y:scroll ;
    padding: 1em;
    z-index: 99;
    cursor: initial;
    background-image: linear-gradient( rgba(0, 0, 0, .9), rgba(0, 0, 0, 0.9) );
    h1{
        color: white;
        text-align: center;
    }
    h2{
        background-color: white;
        color: black;
        width: 100%;
        max-width: 650px;
        text-align: center;
        margin: .5em auto;
        padding: .5em ;
        border-radius: 10px;
        cursor: pointer;
        font-size: 1.2em;
    }
    p{
        margin: .5em auto;
        width: 100%;
        max-width: 650px;
        background-color: bisque;
        color: black;
        padding: 1em 1.5em;
        cursor: initial;
        text-transform: capitalize;
        border-radius:10px ;
        font-size: 1.2em;
        text-align: justify;
        line-height: 30px;
    }
    .innerShowCase {
        width: 100%;
        max-width: 650px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        img {
            margin: .5em 0;
            filter: brightness(.6);
            border-radius: 10px;
            transition: filter .3s ease;
            @media screen and (max-width:600px) {
                filter: none;
            }
            &:hover{
                filter: brightness(1);
            }
        }
    }
}

.Press {
    width: 100%;
    margin: 1em auto;
    max-width: 1200px;
    padding: 1em;
    & > h1 {
        font-size: 2.5em;
        @media screen and (max-width:450px){
            margin-top: 0;
        }
    }
    .innerPressHolder {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
}

.pressCard{
    width: 100%;
    max-width: 300px;
    height: 300px;
    margin: .5em;
    position: relative;
    
    @media screen and (max-width : 380px){
        max-width: 250px;
        height: 250px;
    }
    
    img{
        width: 100%;
        height: 100%;
        filter: brightness(.4);
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        border-radius: 10px;
        transition: all .3s ease;
        
    }
    label {
        font-weight: 500;
        text-transform: capitalize;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: start;
        position: absolute;
        height: 100%;
        z-index: 9;
        color: white;
        padding-top: 1em;
    }
}

.SmallScreenMenu{
    width: 100%;
    position: fixed;
    top: 2em;
    transform: translateY(-700px);
    left: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    transition: transform .3s ease;
    .innerSmallScreenMenuHolder{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 300px;
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        justify-content: center;
        padding: 1em;
        a {
            text-align: center;
            margin: .2em 0;
            color: rgb(255, 255, 255);
            background-color: rgb(0, 0, 0);
            border-radius: 10px;
            padding: .5em;
            font-size: 1.1em;
            cursor: pointer;
        }
    }
}

.contactHolder {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 2em;
    &> h1 {
        text-align: center;
        font-size: 3em;
    }
    .innerContactHolder{
        width: 100%;
        max-width: 1200px;
        display: flex;
        margin: 0 auto;
        padding: 1em;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
        iframe {
            border-radius: 20px;
            flex-grow: 1;
            border: 7px solid black;
            @media screen and (max-width:500px){
                width: 100%;
            }
        }
        form {
            flex-grow: 1;
            background-color: rgb(0, 0, 0);
            border-radius: 20px;
            padding: 2em;
            max-width: 400px;
            @media screen and (max-width:500px){
                padding: 2em 1.5em;
            }
            div {
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-bottom: 1em;
                label {
                    font-size: 1em;
                    padding-left: .2em;
                    padding-bottom: .4em;
                    color: white;
                    font-weight: 800;
                }
                input {
                    padding: 1em 1em;
                    border-radius: 10px;
                    border: 0px solid black;
                    text-transform: capitalize;
                    background-color: rgb(255, 255, 255);
                    outline: none;
                }
                textarea {
                    resize: none;
                    border: 0px solid black;
                    outline: none;
                    border-radius: 10px;
                    font-size: .9em;
                    padding: 1em;
                    text-transform: capitalize;
                    min-height: 150px;
                }
            }
            input[type="submit"] {
                width: 100%;
                background-color: rgb(255, 255, 255);
                color: rgb(0, 0, 0);
                font-size: 1em;
                outline: none;
                border: none;
                padding: 1em;
                border-radius: 10px;
                text-transform: capitalize;
                cursor: pointer;
            }
        }
    }
}

footer {
    width: 100%;
    background-color: black;
    color : white;
    display: flex;
    justify-content: space-between;
    padding: 1em;
    flex-wrap: wrap;
    @media screen and (max-width:600px){
        span{
            margin: .5em 0;
            text-align: center;
            display: block;
            width: 100%;
        }
    }
}