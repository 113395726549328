@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');
@import "./NavBar";

::selection {
    color: black;
    background: bisque;
}
* {
    box-sizing: border-box;
    text-decoration:  none;
    font-family: "Arimo", sans-serif;
    cursor: none;
    scroll-behavior: smooth;
    @media screen and (max-width : 1000px){
        cursor : auto
    }
}

body {
    margin: 0;
    
}