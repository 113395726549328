@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap");
.costumCurser {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  transition: transform 0.2s ease-in-out;
  transform-origin: center;
  pointer-events: none;
  z-index: 99;
}

nav {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1em;
  padding-top: 2em;
  position: absolute;
  top: 0;
}
nav .innerNav {
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav .innerNav .Logo {
  color: white;
  font-size: 1.5em;
}
@media screen and (max-width: 450px) {
  nav .innerNav .Logo {
    font-size: 1.5em;
  }
}
nav .innerNav .Logo span {
  font-size: 0.7em;
  margin-left: -0.2em;
}
nav .innerNav .menuHolder {
  display: flex;
  align-items: center;
}
nav .innerNav .menuHolder a {
  color: white;
  font-size: 1.2em;
  margin-right: 1em;
  font-weight: 600;
  transition: font-size 0.3s ease;
}

.burgerMenu {
  font-size: 2.2em;
  color: white;
}

.mouse {
  width: 32px;
  height: 72px;
  border: 3px solid rgba(255, 255, 255, 0.66);
  border-radius: 60px;
  position: absolute;
  bottom: 1em;
}
.mouse::before {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.66);
  border-radius: 50%;
  opacity: 1;
  animation: wheel 2s infinite;
  -webkit-animation: wheel 2s infinite;
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 60px;
  }
}
.Hero {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0.7)), url("../images/hero.jpg");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Hero .HeroTitle {
  color: rgba(255, 255, 255, 0.66);
  font-size: 4em;
  z-index: 99;
  text-align: center;
  max-width: 1000px;
  line-height: 1.2em;
}
@media screen and (max-width: 450px) {
  .Hero .HeroTitle {
    font-size: 2em;
    padding: 0.5em;
  }
}

.Projects {
  width: 100%;
  max-width: 1300px;
  padding: 1em;
  margin: 0 auto;
}
.Projects > h2 {
  font-size: 2em;
}
.Projects .ProjectsHolder {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.Projects .ProjectsHolder .Project {
  width: 400px;
  min-width: 300px;
  margin: 0.5em;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.Projects .ProjectsHolder .Project > img {
  width: 100%;
  height: 266.66px;
  filter: brightness(0.4);
  transition: filter 0.3s ease;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 10px;
}
@media screen and (max-width: 450px) {
  .Projects .ProjectsHolder .Project > img {
    height: auto;
  }
}
.Projects .ProjectsHolder .Project > img:hover {
  filter: brightness(1);
}
.Projects .ProjectsHolder .Project label {
  text-align: center;
  margin: 0 auto;
  display: block;
  background-color: bisque;
  padding: 0.5em;
  color: rgba(0, 0, 0, 0.839);
  position: absolute;
  border-bottom-right-radius: 10px;
  top: 0;
  left: 0;
  font-size: 1.2em;
}

.About {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("https://images.adsttc.com/media/images/58c6/8f46/e58e/ce27/8c00/016f/large_jpg/Figura_3.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
  padding: 4em 1em;
  margin-top: 2.5em;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
@media screen and (max-width: 1200px) {
  .About {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("https://images.adsttc.com/media/images/58c6/8f46/e58e/ce27/8c00/016f/large_jpg/Figura_3.jpg");
  }
}
.About h1 {
  font-size: 4em;
  color: white;
  text-align: center;
  margin-top: 0;
}
@media screen and (max-width: 500px) {
  .About h1 {
    font-size: 2.5em;
  }
}
.About p {
  font-size: 1.5em;
  color: white;
  text-transform: capitalize;
  text-align: center;
  line-height: 1.4em;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 2em;
}
@media screen and (max-width: 500px) {
  .About p {
    font-size: 1.3em;
    text-align: center;
    padding: 1em;
    line-height: 1.5em;
  }
}
.About button {
  margin: 0 auto;
  display: block;
  background-color: white;
  color: rgba(0, 0, 0, 0.804);
  border: none;
  outline: none;
  font-size: 1.2em;
  padding: 0.5em 1em;
  border-radius: 10px;
}

.Services {
  width: 100%;
  margin-top: 5em;
}
@media screen and (max-width: 500px) {
  .Services {
    margin-top: 2em;
  }
}
.Services .innerServices {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}
.Services .innerServices h1 {
  font-size: 5em;
  margin-bottom: 0.1em;
}
@media screen and (max-width: 500px) {
  .Services .innerServices h1 {
    font-size: 2.5em;
    text-align: center;
  }
}
.Services .innerServices .servicesHolder {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 1em;
}
.Services .innerServices .service {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
  width: 100%;
  width: 400px;
  margin: 0.5em;
  overflow: hidden;
  transition: border-radius 0.3s ease;
  flex-grow: 1;
  max-height: 350px;
}
.Services .innerServices .service .innerServiceHolder {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;
}
.Services .innerServices .service .innerServiceHolder label {
  font-size: 2.5em;
  font-weight: 700;
  color: white;
  margin-bottom: 0.4em;
  text-align: center;
}
.Services .innerServices .service .innerServiceHolder button {
  font-size: 0.9em;
  background: white;
  border: none;
  outline: none;
  padding: 0.8em 1.8em;
  border-radius: 10px;
  align-items: center;
  transition: background-color 0.3s ease;
}
.Services .innerServices .service .innerServiceHolder button:hover {
  background-color: bisque;
}
.Services .innerServices .service img {
  width: 100%;
  border-radius: 15px;
  filter: brightness(0.2);
  min-height: 304px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.seeMore {
  display: block;
  margin: 0 auto;
  margin-top: 1em;
  background-color: bisque;
  padding: 1em 2em;
  width: 100%;
  max-width: 400px;
  color: rgba(0, 0, 0, 0.839);
  text-align: center;
  border-radius: 10px;
  font-size: 1.1em;
}
.seeMore:hover {
  cursor: pointer;
}

.showCase {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow-y: scroll;
  padding: 1em;
  z-index: 99;
  cursor: initial;
  background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9));
}
.showCase h1 {
  color: white;
  text-align: center;
}
.showCase h2 {
  background-color: white;
  color: black;
  width: 100%;
  max-width: 650px;
  text-align: center;
  margin: 0.5em auto;
  padding: 0.5em;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.2em;
}
.showCase p {
  margin: 0.5em auto;
  width: 100%;
  max-width: 650px;
  background-color: bisque;
  color: black;
  padding: 1em 1.5em;
  cursor: initial;
  text-transform: capitalize;
  border-radius: 10px;
  font-size: 1.2em;
  text-align: justify;
  line-height: 30px;
}
.showCase .innerShowCase {
  width: 100%;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}
.showCase .innerShowCase img {
  margin: 0.5em 0;
  filter: brightness(0.6);
  border-radius: 10px;
  transition: filter 0.3s ease;
}
@media screen and (max-width: 600px) {
  .showCase .innerShowCase img {
    filter: none;
  }
}
.showCase .innerShowCase img:hover {
  filter: brightness(1);
}

.Press {
  width: 100%;
  margin: 1em auto;
  max-width: 1200px;
  padding: 1em;
}
.Press > h1 {
  font-size: 2.5em;
}
@media screen and (max-width: 450px) {
  .Press > h1 {
    margin-top: 0;
  }
}
.Press .innerPressHolder {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.pressCard {
  width: 100%;
  max-width: 300px;
  height: 300px;
  margin: 0.5em;
  position: relative;
}
@media screen and (max-width: 380px) {
  .pressCard {
    max-width: 250px;
    height: 250px;
  }
}
.pressCard img {
  width: 100%;
  height: 100%;
  filter: brightness(0.4);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 10px;
  transition: all 0.3s ease;
}
.pressCard label {
  font-weight: 500;
  text-transform: capitalize;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  position: absolute;
  height: 100%;
  z-index: 9;
  color: white;
  padding-top: 1em;
}

.SmallScreenMenu {
  width: 100%;
  position: fixed;
  top: 2em;
  transform: translateY(-700px);
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  transition: transform 0.3s ease;
}
.SmallScreenMenu .innerSmallScreenMenuHolder {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  justify-content: center;
  padding: 1em;
}
.SmallScreenMenu .innerSmallScreenMenuHolder a {
  text-align: center;
  margin: 0.2em 0;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  padding: 0.5em;
  font-size: 1.1em;
  cursor: pointer;
}

.contactHolder {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2em;
}
.contactHolder > h1 {
  text-align: center;
  font-size: 3em;
}
.contactHolder .innerContactHolder {
  width: 100%;
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  padding: 1em;
  flex-wrap: wrap;
  -moz-column-gap: 20px;
       column-gap: 20px;
  row-gap: 20px;
}
.contactHolder .innerContactHolder iframe {
  border-radius: 20px;
  flex-grow: 1;
  border: 7px solid black;
}
@media screen and (max-width: 500px) {
  .contactHolder .innerContactHolder iframe {
    width: 100%;
  }
}
.contactHolder .innerContactHolder form {
  flex-grow: 1;
  background-color: rgb(0, 0, 0);
  border-radius: 20px;
  padding: 2em;
  max-width: 400px;
}
@media screen and (max-width: 500px) {
  .contactHolder .innerContactHolder form {
    padding: 2em 1.5em;
  }
}
.contactHolder .innerContactHolder form div {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}
.contactHolder .innerContactHolder form div label {
  font-size: 1em;
  padding-left: 0.2em;
  padding-bottom: 0.4em;
  color: white;
  font-weight: 800;
}
.contactHolder .innerContactHolder form div input {
  padding: 1em 1em;
  border-radius: 10px;
  border: 0px solid black;
  text-transform: capitalize;
  background-color: rgb(255, 255, 255);
  outline: none;
}
.contactHolder .innerContactHolder form div textarea {
  resize: none;
  border: 0px solid black;
  outline: none;
  border-radius: 10px;
  font-size: 0.9em;
  padding: 1em;
  text-transform: capitalize;
  min-height: 150px;
}
.contactHolder .innerContactHolder form input[type=submit] {
  width: 100%;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  font-size: 1em;
  outline: none;
  border: none;
  padding: 1em;
  border-radius: 10px;
  text-transform: capitalize;
  cursor: pointer;
}

footer {
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 1em;
  flex-wrap: wrap;
}
@media screen and (max-width: 600px) {
  footer span {
    margin: 0.5em 0;
    text-align: center;
    display: block;
    width: 100%;
  }
}

::-moz-selection {
  color: black;
  background: bisque;
}

::selection {
  color: black;
  background: bisque;
}

* {
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Arimo", sans-serif;
  cursor: none;
  scroll-behavior: smooth;
}
@media screen and (max-width: 1000px) {
  * {
    cursor: auto;
  }
}

body {
  margin: 0;
}/*# sourceMappingURL=main.css.map */